import React from "react";

export default function Title({ text, className }) {
  return (
    <h1
      className={`${className} text-center uppercase text-5xl 2xl:text-[150px] md:text-[100px]  font-bold`}
    >
      {text}
    </h1>
  );
}
