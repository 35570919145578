import React, { useState } from "react";
import Button from "../components/button";
import SubTitle from "../components/subtitle";
import Title from "../components/title";
import { useNavigate } from "react-router-dom";

const openseaUrl = "https://opensea.io/collection/prime-kong-planet-by-pap"

export default function Register() {
  const [recaptcha, setRecaptcha] = useState(false);
  const navigate = useNavigate();
  return (
    <>
        <Title text={"SOLD OUT."} />
            <SubTitle text={"All Prime Kongs have been sold out. You can still get your Prime Kong Planet NFT directly from OpenSea."} />     
            <Button
                text={"Get more Kongs on Opensea"}
                onClick={() => window.open(openseaUrl, "_blank")}
            ></Button>                   
    </>
  );
}
