import './App.css';
import RouterComponent from './router';

function App() {
  return (
    <RouterComponent />
  );
}

export default App;
