import React, { useState } from "react";
import { Link } from "react-router-dom";
import SmallButton from "./smallButton";
import { Transition } from "@headlessui/react";

export function Layout({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full h-screen flex flex-col">
      <div className="flex bg-black h-[160px] text-white items-center px-[20px] md:px-[80px] justify-between md:justify-around">
        <Link to={"/"}>
          <img src="/assets/images/logo.png" className="" alt="logo" />
        </Link>
        <ul className="hidden md:flex items-center">
          <li className="px-[15px]">
            <a href="https://primeapeplanet.com" target="_blank" rel="noreferrer">Roadmap</a>
          </li>
          <li className="px-[15px]">
            <a href="https://primeapeplanet.com" target="_blank" rel="noreferrer">Artist</a>
          </li>
          <li className="px-[15px]">
            <a href="https://primeapeplanet.com/" target="_blank" rel="noreferrer">Team</a>
          </li>
          <li className="px-[15px]">
            <a href="https://primeapeplanet.com/" target="_blank" rel="noreferrer">Faq</a>
          </li>
          <li className="px-[15px]">
            <a href="https://www.instagram.com/primekongplanet/" target="_blank" rel="noreferrer">
              <svg
                class="h-6 w-6 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />{" "}
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
              </svg>
            </a>
          </li>
          <li className="px-[15px]">
            <a href="https://twitter.com/KongPlanet" target="_blank" rel="noreferrer">
              <svg
                class="h-6 w-6 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
              </svg>
            </a>
          </li>
        </ul>
        <SmallButton text={"join our discord"} className="hidden md:block"
            onClick={() => window.open("https://discord.gg/primeapeplanet", "_blank")}
        />
        <div className="-mr-2 flex md:hidden">
          <div
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {!isOpen ? (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            ) : (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </div>
        </div>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="absolute top-[145px] w-[calc(100%-20px)]"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <ul className="flex flex-col bg-black px-[15px] items-stretch py-10">
                <li className="py-[15px]">
                  <a href="https://primeapeplanet.com/" target="_blank" rel="noreferrer">Roadmap</a>
                </li>
                <li className="py-[15px]">
                  <a href="https://primeapeplanet.com/" target="_blank" rel="noreferrer">Artist</a>
                </li>
                <li className="py-[15px]">
                  <a href="https://primeapeplanet.com/" target="_blank" rel="noreferrer">Team</a>
                </li>
                <li className="py-[15px]">
                  <a href="https://primeapeplanet.com/" target="_blank" rel="noreferrer">Faq</a>
                </li>
                <div className="flex items-center">
                  <li className="pr-[15px]">
                    <a href="https://www.instagram.com/primekongplanet/" target="_blank" rel="noreferrer">
                      <svg
                        class="h-6 w-6 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <rect
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          ry="5"
                        />{" "}
                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                      </svg>
                    </a>
                  </li>
                  <li className="pl-[15px] mr-auto">
                    <a href="https://twitter.com/KongPlanet" target="_blank" rel="noreferrer">
                      <svg
                        class="h-6 w-6 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                      </svg>
                    </a>
                  </li>
                  <SmallButton text={"join our discord"} 
                    onClick={() => window.open("https://discord.gg/primeapeplanet", "_blank")}
                  />
                </div>
              </ul>
            </div>
          )}
        </Transition>
      </div>
      <div className="flex-1 text-white overflow-auto">
        <div className="bg-body-image bg-cover flex items-center justify-center flex-col min-h-full md:p-24 p-12 md:pt-12">
          {children}
        </div>
      </div>
    </div>
  );
}
