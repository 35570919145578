import React from "react";

export default function SmallButton({ text, onClick, className }) {
  return (
    <div
      onClick={onClick}
      className={`${className} px-[30px] py-[15px] whitespace-nowrap select-none rounded-full bg-white text-black font-bold cursor-pointer hover:bg-slate-300`}
    >
      {text}
    </div>
  );
}
