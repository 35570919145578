import React from "react";

export default function Button({ text, onClick }) {
  return (
    <div
      onClick={onClick}
      className="2xl:px-[90px] 2xl:py-[26px] px-10 py-3 whitespace-nowrap select-none md:mt-16 mt-10 rounded-full bg-white text-black md:text-2xl text-lg font-semibold cursor-pointer hover:bg-slate-300"
    >
      {text}
    </div>
  );
}
