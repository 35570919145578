import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Layout } from "./components/layout";
import Congrats from "./screens/congrates";
import OpensIn from "./screens/opensIn";
import Register from "./screens/register";
import Registered from "./screens/registered";
import RegisteredSuccessfully from "./screens/registeredSuccess";
import Sorry from "./screens/sorry";
import Closed from "./pages/closed"

export default function RouterComponent() {
  return (
    <Router>
      <Layout>
        <Routes>
            <Route path="/" element={<Closed />} />
        {/*  <Route path="/register" element={<Register />} />
          <Route
            path="/registered-success"
            element={<RegisteredSuccessfully />}
          />
          <Route path="/sorry" element={<Sorry />} />
          <Route path="/registered" element={<Registered />} />
          <Route path="/opensin" element={<OpensIn />} />
            <Route path="/congrats" element={<Congrats />} />*/}
  </Routes>
      </Layout>
    </Router>
  );
}
